import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import {PurchaseLine} from "../../stores/MarketsStore";
// import PaymentIcon from '../shorts/paymentIcons/PaymentIcons'
// import { Container, Row, Col } from "react-bootstrap"

const Purchases = observer(() => {
    const {marketsStore} = useRootStore()
    const {loadPurchases, currentMarketPurchases, setCurrentPurchase, currentPurchase} = marketsStore

    useEffect(() => {
        loadPurchases()
    }, [])

    const UpdatePurchase = (purchase: any) => {

        setCurrentPurchase(purchase)
    }

    const productDict = {
        'water': 'Вода',
        'bottle': 'Тара',
        'cork': 'Пробка',
        'prepay': 'Предоплата'
    }

    const payDict = {
        'cash': 'Наличные',
        'emoney': 'Банк',
        'qr': 'QR',
        'prepay': 'Счёт клиента'
    }



    return (
        <div className="events">
            {
                currentPurchase &&
                <div>
                    <div>Дата: {currentPurchase.date} Сумма: {currentPurchase.sum} руб.</div>
                    <div>Товары</div>
                    <table className="table table--border table--main">
                        <thead>
                        <tr>
                            <td>Товар</td>
                            <td>Количество</td>
                            <td>Итог</td>
                        </tr>
                        </thead>
                        <tbody>
                    {currentPurchase.lines.map((line, i) => {
                        return (
                            <tr>
                            <td>{productDict[line.product as keyof typeof productDict]}</td>
                            <td>{line.qt}</td>
                            <td>{line.cost}</td>
                        </tr>
                        )
                    })}
                        </tbody>
                    </table>

                    <div>Оплата</div>
                    <table className="table table--border table--main">
                        <thead>
                        <tr>
                            <td>Вид оплаты</td>
                            <td>Сумма оплаты</td>
                            <td>Сумма возврата</td>
                            <td>Удержано</td>
                            <td>Забыто</td>
                        </tr>
                        </thead>
                        <tbody>
                        {currentPurchase.pays.map((pay, i) => {
                            return (
                                <tr>
                                    <td>{payDict[pay.type as keyof typeof payDict]}</td>
                                    <td>{pay.taken}</td>
                                    <td>{pay.given}</td>
                                    <td>{pay.locked}</td>
                                    <td>{pay.forgotten}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }

            <table className='table table--hover'>
                <thead>
                <tr>
                    <td>Дата</td>
                    <td>Суммы</td>
                    <td>Итог</td>
                </tr>
                </thead>
                <tbody>
                {currentMarketPurchases?.map((purchase, i) => {
                    return (
                        <tr
                            key={i}
                            onClick={() => UpdatePurchase(purchase)}
                            className={purchase?.id === currentPurchase?.id ? 'active' : ''}
                        >
                            <td>{purchase.date}</td>
                            <td>{
                                purchase.lines?.map( (line: PurchaseLine, i: number) => {
                                    return (
                                        <>
                                            {productDict[line.product as keyof typeof productDict]} {line.qt}
                                            {(i+1)<purchase.lines.length && <> / </>}
                                        </>
                                    )
                                })
                            }</td>
                            <td>{purchase.sum} руб.</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )


})

export default Purchases